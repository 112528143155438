<template>
  <div>
    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item">
          <router-link
            :class="{
              active: $route.name === 'employee-list'
            }"
            class="navbar-nav-link"
            to="/employees"
          >
            Active
            <span class="badge badge-pill bg-warning-400 ml-auto ml-md-0">
              {{ active_disable.active }}
            </span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :class="{
              active: $route.name === 'employee-list-disabled'
            }"
            class="navbar-nav-link"
            to="/employees-disabled"
          >
            Disabled
            <span class="badge badge-pill bg-warning-400 ml-auto ml-md-0">
              {{ active_disable.disable }}
            </span>
          </router-link>
        </li>
      </template>
    </Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <breadcrumb-component
            :links="[{ name: 'Employee Management' }]"
          ></breadcrumb-component>
          <div class="row mb-2">
            <div class="col-md-6 mb-1">
              Displaying
              <span class="font-weight-bold text-green-700">{{
                pagination.displaying
              }}</span>
              of
              <span class="font-weight-bold text-green-700">{{
                pagination.totalData
              }}</span>
              Result
            </div>
            <div class="col-md-2 mb-1">
              <router-link
                class="btn bg-green-600 btn-block rounded-round btn-labeled btn-labeled-left"
                to="/employees/create"
                v-if="isHavePermission('GET:employee.default.create')"
              >
                <b>
                  <i class="icon-user-plus"></i>
                </b>
                Add New Recruit
              </router-link>
            </div>
            <div class="col-md-2 mb-1">
              <download-excel
                :data="json_data"
                :fields="json_fields"
                :name="`hrms_export_employee_${json_filename}.xls`"
                class="btn bg-green-600 btn-block rounded-round btn-labeled btn-labeled-left"
                worksheet="Employee"
              >
                <b>
                  <i class="icon-filter3"></i>
                </b>
                Export
              </download-excel>
            </div>
            <div class="col-md-2 mb-1">
              <b-button
                block
                class="btn bg-white text-secondary rounded-round btn-labeled btn-labeled-left"
                v-b-toggle.collapse-1
              >
                <b>
                  <i class="icon-filter3"></i>
                </b>
                Filter
              </b-button>
            </div>

            <div class="col-md-12">
              <b-collapse class="mt-2" id="collapse-1">
                <div class="card card-body">
                  <div class="row">
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="employee_no">Employee ID</label>
                        <input
                          class="form-control"
                          id="employee_no"
                          name="employee_no"
                          placeholder="Employee ID"
                          type="text"
                          v-model="filter.employee_no"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="fullname">Name</label>
                        <input
                          class="form-control"
                          id="fullname"
                          name="fullname"
                          placeholder="Name"
                          type="text"
                          v-model="filter.fullname"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="email">Email</label>
                        <input
                          class="form-control"
                          id="email"
                          name="email"
                          placeholder="Email"
                          type="text"
                          v-model="filter.email"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="branch_id">Cabang</label>
                        <model-select
                          :options="branches"
                          id="branch_id"
                          placeholder="select item"
                          v-model="filter.cabang"
                        ></model-select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="department_id">Department</label>
                        <model-select
                          :options="departments"
                          @input="occupationByDepartment"
                          id="department_id"
                          placeholder="select item"
                          v-model="filter.department"
                        ></model-select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="branch_id">Position</label>
                        <model-select
                          :options="occupations"
                          placeholder="select item"
                          v-model="filter.position"
                        ></model-select>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-end">
                    <div class="col-md-2">
                      <button
                        @click="resetFilter"
                        class="btn bg-warning-600 btn-block rounded-round btn-labeled btn-labeled-left"
                        type="button"
                      >
                        <b>
                          <i class="icon-reset"></i>
                        </b>
                        Reset
                      </button>
                    </div>
                    <div class="col-md-2">
                      <button
                        @click="applyFilter"
                        class="btn bg-green-700 btn-block rounded-round btn-labeled btn-labeled-left"
                        type="button"
                      >
                        <b>
                          <i class="icon-check"></i>
                        </b>
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card" v-if="!$root.mobile">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="text-muted font-weight-bold">
                        <a
                          @click="sort($event, 'employee_no')"
                          href="javascript:void(0)"
                        >
                          Employee ID
                        </a>
                      </th>
                      <th class="text-muted font-weight-bold">
                        <a
                          @click="sort($event, 'firstname')"
                          href="javascript:void(0)"
                        >
                          Name
                        </a>
                      </th>
                      <th class="text-muted font-weight-bold">
                        <a
                          @click="sort($event, 'email')"
                          href="javascript:void(0)"
                        >
                          Email
                        </a>
                      </th>
                      <th class="text-muted font-weight-bold">
                        <a
                          @click="sort($event, 'branch_id')"
                          href="javascript:void(0)"
                        >
                          Cabang
                        </a>
                      </th>
                      <th class="text-muted font-weight-bold">
                        <a
                          @click="sort($event, 'department_id')"
                          href="javascript:void(0)"
                        >
                          Department
                        </a>
                      </th>
                      <th class="text-muted font-weight-bold">
                        <a
                          @click="sort($event, 'occupation_id')"
                          href="javascript:void(0)"
                        >
                          Position
                        </a>
                      </th>
                      <th class="text-muted font-weight-bold">
                        Status
                      </th>
                      <th class="text-muted font-weight-bold">
                        <a
                          @click="sort($event, 'resign_date')"
                          href="javascript:void(0)"
                        >
                          Resign Date
                        </a>
                      </th>
                      <th class="text-muted font-weight-bold">
                        <a
                          @click="sort($event, 'wp_first_name')"
                          href="javascript:void(0)"
                        >
                          Wakil Pialang
                        </a>
                      </th>
                      <th class="text-muted font-weight-bold"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="loading">
                      <tr :key="a" v-for="a in filter.limit">
                        <td :key="b" v-for="b in 9">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text :lines="1" />
                          </content-placeholders>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <template v-if="employees.length === 0">
                        <tr>
                          <td colspan="9">No Data</td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr :key="employee.id" v-for="employee in employees">
                          <td scope="row">
                            <template
                              v-if="
                                typeof employee.employee_no !== 'undefined' &&
                                  employee.employee_no
                              "
                              >{{ employee.employee_no }}
                            </template>
                            <template v-else>-</template>
                          </td>
                          <td>
                            {{ employee.firstname + ' ' + employee.lastname }}
                          </td>
                          <td>{{ employee.email }}</td>
                          <td>
                            <template
                              v-if="
                                typeof employee.branch !== 'undefined' &&
                                  employee.branch
                              "
                              >{{ employee.branch.name }}
                            </template>
                            <template v-else>-</template>
                          </td>
                          <td>
                            <template
                              v-if="
                                typeof employee.department !== 'undefined' &&
                                  employee.department
                              "
                              >{{ employee.department.name }}
                            </template>
                            <template v-else>-</template>
                          </td>
                          <td>
                            <template
                              v-if="
                                typeof employee.occupation !== 'undefined' &&
                                  employee.occupation
                              "
                              >{{ employee.occupation.name }}
                            </template>
                            <template v-else>-</template>
                          </td>
                          <td>
                            <template v-if="employee.user.active == 1">
                              Active
                            </template>
                            <template v-else>Resigned</template>
                          </td>
                          <td>
                            {{ employee.resign_date }}
                          </td>
                          <td>
                            {{ employee.wp_first_name }}
                          </td>
                          <td>
                            <div class="list-icons">
                              <div class="list-icons-item dropdown">
                                <a
                                  class="list-icons-item dropdown-toggle caret-0"
                                  data-toggle="dropdown"
                                  href="javascript:void(0)"
                                >
                                  <i
                                    class="fas fa-ellipsis-v text-green-700 fa-2x"
                                  ></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <router-link
                                    :to="`/employees/${employee.id}`"
                                    class="dropdown-item"
                                    v-if="
                                      isHavePermission(
                                        'GET:employee.default.can-see-other-profile'
                                      )
                                    "
                                  >
                                    <i class="icon-file-stats"></i>
                                    See Details
                                  </router-link>
                                  <router-link
                                    :to="`/employees/${employee.id}/edit`"
                                    class="dropdown-item"
                                    v-if="
                                      isHavePermission(
                                        'GET:employee.default.edit'
                                      )
                                    "
                                  >
                                    <i class="icon-file-stats"></i>
                                    Edit HR Data
                                  </router-link>
                                  <router-link
                                    :to="
                                      `/employees/${employee.id}/reset-password`
                                    "
                                    class="dropdown-item"
                                  >
                                    <i class="icon-file-stats"></i>
                                    Reset Password Login
                                  </router-link>
                                  <a
                                    @click="deleteModal(employee)"
                                    class="dropdown-item text-danger"
                                    href="javascript:void(0)"
                                    v-if="
                                      isHavePermission(
                                        'GET:employee.default.reset.login'
                                      )
                                    "
                                  >
                                    <i class="icon-file-stats"></i>
                                    Delete
                                  </a>
                                  <a
                                    @click="resign(employee)"
                                    class="dropdown-item text-danger"
                                    href="javascript:void(0)"
                                    v-if="
                                      isHavePermission(
                                        'GET:employee.default.set.resign'
                                      )
                                    "
                                  >
                                    <i class="icon-file-stats"></i>
                                    Set Resign
                                  </a>
                                  <a
                                    @click="modalMutasi(employee)"
                                    class="dropdown-item text-danger"
                                    href="javascript:void(0)"
                                    v-if="
                                      isHavePermission(
                                        'GET:employee.default.mutasi'
                                      ) && employee.department_id == 32
                                    "
                                  >
                                    <i class="icon-file-stats"></i>
                                    Upgrade / Downgrade
                                  </a>
                                  <a
                                    @click="updateSalesDataModal(employee)"
                                    class="dropdown-item text-danger"
                                    href="javascript:void(0)"
                                    v-if="
                                      isHavePermission(
                                        'GET:employee.default.update.sales'
                                      ) && employee.department_id == 32
                                    "
                                  >
                                    <i class="icon-file-stats"></i>
                                    Edit Sales Data
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
              <template v-else>
                <div class="row">
                  <div class="col-md-12">
                    <div class="card">
                      <template v-if="loading">
                        <div
                          :key="n"
                          class="border-bottom mb-1 d-block p-3"
                          v-for="n in filter.limit"
                        >
                          <content-placeholders>
                            <content-placeholders-heading :img="true" />
                          </content-placeholders>
                        </div>
                      </template>
                      <template v-for="employee in employees">
                        <div
                          :key="employee.id"
                          class="media p-2 pt-0 mt-0 border-bottom"
                        >
                          <div class="mr-3">
                            <router-link :to="`/employees/${employee.id}`">
                              <img
                                alt
                                class="rounded-circle"
                                height="42"
                                src="../scss/global_assets/images/placeholders/placeholder.jpg"
                                width="42"
                              />
                            </router-link>
                          </div>

                          <div class="media-body">
                            <h6 class="mb-0">
                              <router-link :to="`/employees/${employee.id}`">
                                {{
                                  employee.firstname + ' ' + employee.lastname
                                }}
                              </router-link>
                            </h6>
                            <span class="text-muted">
                              <template
                                v-if="
                                  typeof employee.department !== 'undefined' &&
                                    employee.department
                                "
                                >{{ employee.department.name }}</template
                              >
                              <template v-else>-</template>
                              /
                              <template
                                v-if="
                                  typeof employee.occupation !== 'undefined' &&
                                    employee.occupation
                                "
                                >{{ employee.occupation.name }}</template
                              >
                              <template v-else>-</template>
                            </span>
                          </div>

                          <div class="ml-3 align-self-center"></div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
              <paginate
                :click-handler="clickCallback"
                :container-class="
                  'pagination pagination-separated pagination-rounded align-self-center'
                "
                :margin-pages="5"
                :next-class="'page-item'"
                :next-link-class="'page-link'"
                :next-text="'Next'"
                :page-class="'page-item'"
                :page-count="pagination.totalPage"
                :page-link-class="'page-link'"
                :page-range="5"
                :prev-class="'page-item'"
                :prev-link-class="'page-link'"
                :prev-text="'Prev'"
                v-if="!$root.mobile"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      header-class="bg-white"
      id="UpdateSalesData"
      ref="UpdateSalesData"
      title="Update Sales Data"
    >
      <form @submit.prevent="submitUpdateSalesData">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Alias Link</label>
              <input
                type="text"
                class="form-control"
                v-model="sales_update_model.alias_link"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Sales Code</label>
              <input
                type="text"
                class="form-control"
                v-model="sales_update_model.sales_code"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="">Wakil Pialang</label>
              <model-select
                :options="wakil_pialang"
                @searchchange="searchWakilPialang"
                v-model="sales_update_model.wp_id"
              ></model-select>
              <div class="text-info d-block mt-1">
                Kosongkan jika tidak ingin di update
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Sales Division</label>
              <model-select
                :options="[
                  { value: 'Online', text: 'Online' },
                  { value: 'Floor', text: 'Floor' }
                ]"
                placeholder="Pilih Sales Division"
                v-model="sales_update_model.sales_division"
              />
              <div class="text-info d-block mt-1">
                Kosongkan jika tidak ingin di update
              </div>
            </div>
          </div>
        </div>
        <button class="btn bg-green-700 btn-block mt-3" type="submit">
          Submit
        </button>
      </form>
    </b-modal>
    <b-modal
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      header-class="bg-white"
      id="EmployeeResign"
      ref="EmployeeResign"
      title="Set Resign"
    >
      <form @submit.prevent="submitFormResign">
        <div class="form-group">
          <label>Employee</label>
          <input
            class="form-control"
            readonly
            type="text"
            v-model="resign_date.firstname"
          />
        </div>
        <div class="form-group">
          <label>NIK</label>
          <input class="form-control" type="text" v-model="resign_date.nik" />
        </div>
        <div class="form-group">
          <label>Resign Date</label>
          <datepicker
            :input-class="'form-control'"
            id="resign_date"
            placeholder="Pilih tanggal berhenti"
            v-model="resign_date.date"
          />
        </div>
        <button class="btn bg-green-700 btn-block mt-3" type="submit">
          Submit
        </button>
      </form>
    </b-modal>
    <b-modal
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      header-class="bg-white"
      id="ModalMutasi"
      ref="ModalMutasi"
      size="xl"
      title="Upgrade / Downgrade Employee"
    >
      <form @submit.prevent="submitFormMutasi">
        <div class="form-group">
          <label>Employee</label>
          <input
            class="form-control"
            readonly
            type="text"
            v-model="modelMutasi.firstname"
          />
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Old Value</label>
              <model-select
                :options="modelMutasi.occupations"
                v-model="modelMutasi.from_occupation_id"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>New Value</label>
              <model-select
                :options="modelMutasi.occupations"
                v-model="modelMutasi.to_occupation_id"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Upline</label>
          <model-select
            :options="modelMutasi.uplines"
            @searchchange="selectSearch"
            placeholder="Pilih Upline"
            v-model="modelMutasi.upline_id"
          />
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Sales Code</label>
              <input
                class="form-control"
                type="text"
                v-model="modelMutasi.sales_code"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>History Date</label>
              <datepicker
                :input-class="'form-control'"
                @input="logthis"
                id="history_date"
                placeholder="Pilih tanggal"
                v-model="modelMutasi.history_date"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Wakil Pialang</label>
          <model-select
            :options="modelMutasi.wakil_pialang"
            placeholder="Pilih Wakil Pialang"
            v-model="modelMutasi.wp_id"
          />
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Sales Division</label>
              <model-select
                :options="[
                  { value: 'Online', text: 'Online' },
                  { value: 'Floor', text: 'Floor' }
                ]"
                placeholder="Pilih Divisi Sales"
                v-model="modelMutasi.sales_division"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Alias Link</label>
              <input
                class="form-control"
                type="text"
                v-model="modelMutasi.alias_link"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="note">Note</label>
          <textarea
            class="form-control"
            cols="30"
            id="note"
            name="note"
            rows="10"
            v-model="modelMutasi.note"
          ></textarea>
        </div>
        <button class="btn bg-green-700 btn-block mt-3" type="submit">
          Submit
        </button>
      </form>
    </b-modal>
  </div>
</template>
<script>
import EmployeeApi from '../apis/Employee'
import BackofficeApi from '../apis/Backoffice'
import DepartmentApi from '../apis/Department'
import OccupationApi from '../apis/Occupation'
import BackofficeSalesApi from '../apis/BackofficeSales'
import Paginate from '../components/Paginate'
import Hrms from '../services/hrms'
import Master from '../services/master'
import { ModelSelect } from 'vue-search-select'
import BranchApi from '../apis/Branch'
import PermissionMixin from '../mixins/PermissionMixin'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import BackOfficeSales from '../apis/BackofficeSales'

export default {
  mixins: [PermissionMixin],
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      ),
    DownloadExcel: () =>
      import(
        /* webpackChunkName: "component-download-excel" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/JsonExcel'
      ),
    Paginate,
    Datepicker,
    ModelSelect
  },
  data() {
    return {
      resign_date: {
        user_id: 0,
        firstname: '',
        date: '',
        nik: '',
        id: ''
      },
      employees: [],
      loading: true,
      pagination: {
        totalPage: 0,
        currentPage: 1,
        totalData: 0,
        displaying: 0
      },
      employeeCount: {
        active: 0,
        disable: 0
      },
      filter: {
        employee_no: null,
        fullname: null,
        email: null,
        cabang: null,
        department: null,
        position: null,
        limit: 12
      },
      sort_filter: {
        sort: 'asc',
        order: 'id'
      },
      branches: [],
      lives: [],
      departments: [],
      occupations: [],
      json_filename: new Date(Date.now())
        .toLocaleString()
        .slice(0, 3)
        .match(/[0-9]/i)
        ? new Date(Date.now())
            .toLocaleString()
            .split(' ')[0]
            .split(',')[0]
        : new Date(Date.now()).toLocaleString().split(' ')[1] +
          ' ' +
          new Date(Date.now()).toLocaleString().split(' ')[2] +
          ' ' +
          new Date(Date.now()).toLocaleString().split(' ')[3],
      json_fields: {
        'Employee ID': 'employee_id',
        Name: 'name',
        Email: 'email',
        Cabang: 'cabang',
        Department: 'department',
        Position: 'position'
      },
      json_data: [],
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          }
        ]
      ],
      modelDelete: {
        id: 0
      },
      active_disable: {
        active: 0,
        disable: 0
      },
      modelMutasi: {
        user_id: 0,
        sales_id: 0,
        firstname: '',
        employee: {
          firstname: '',
          lastname: '',
          email: '',
          employee_no: '',
          branch_id: '',
          department_id: '',
          occupation_id: ''
        },
        upline_id: 0,
        sales_code: 0,
        wp_id: 0,
        history_date: '',
        alias_link: 0,
        sales_division: 0,
        sales_divisions: [
          {
            id: 'ONLINE',
            text: 'ONLINE'
          },
          {
            id: 'FLOOR',
            text: 'FLOOR'
          }
        ],
        occupation_id: 0,
        from_occupation_id: 0,
        to_occupation_id: 0,
        note: '',
        occupations: [],
        uplines: [],
        wakil_pialang: []
      },
      sales_update_model: {
        sales_code: '',
        wp_id: {
          value: '',
          text: ''
        },
        sales_division: {
          value: '',
          text: ''
        },
        alias_link: '',
        sales_id: ''
      },
      wakil_pialang: []
    }
  },
  mounted() {},
  methods: {
    updateEmployee(payload) {
      EmployeeApi.update(payload.id, payload)
        .then(res => {
          console.log(res)
        })
        .catch(res => {
          console.log(res)
        })
    },
    changeValue(e) {
      console.log('e', e)
    },
    searchWakilPialang(e) {
      if (e.length >= 2) {
        BackofficeSalesApi.WpSearchAjaxByCode(e)
          .then(result => {
            this.wakil_pialang = result.data.data.map(x => {
              return {
                value: x.id,
                text:
                  x.wp_code +
                  ' (' +
                  x.first_name +
                  ' ' +
                  x.last_name +
                  '/ ' +
                  x.email +
                  ')'
              }
            })
            console.log('this.wakil_pialang', this.wakil_pialang)
            this.isLoading = false
          })
          .catch(err => {
            console.log(err.response)
            this.isLoading = false
          })
      }
    },
    submitUpdateSalesData() {
      this.loading = true
      BackofficeSalesApi.SalesUpdate(this.sales_update_model.sales_id, {
        sales_code: this.sales_update_model.sales_code,
        wp_id: this.sales_update_model.wp_id.value,
        sales_division: this.sales_update_model.sales_division.value,
        alias_link: this.sales_update_model.alias_link
      })
        .then(res => {
          this.$swal('Success', 'Data berhasil di update', 'success')
          this.$refs['UpdateSalesData'].hide()
        })
        .catch(res => {
          console.log(res)
          this.$refs['UpdateSalesData'].hide()
        })
      this.applyFilter()
    },
    updateSalesDataModal(employee) {
      BackofficeSalesApi.salesView({ id: employee.user_id })
        .then(res => {
          const salesData = res.data.data;
          this.sales_update_model.sales_code = salesData.sales_code;
          this.sales_update_model.wp_id = {
            value: salesData.wp_id,
            text: `${salesData.wp.wp_code} (${salesData.wp.first_name} / ${salesData.wp.email})`
          };
          this.sales_update_model.sales_division = {
            value: salesData.sales_division,
            text: salesData.sales_division
          };
          this.sales_update_model.alias_link = salesData.alias_link;
          this.sales_update_model.sales_id = salesData.id;

          // Populate wakil_pialang options
          this.wakil_pialang = [{
            value: salesData.wp.id,
            text: `${salesData.wp.wp_code} (${salesData.wp.first_name} / ${salesData.wp.email})`
          }];

          this.$refs['UpdateSalesData'].show();
        })
        .catch(res => {
          console.log(res);
        });
    },
    submitFormResign() {
      let that = this
      let payload = {
        user_id: this.resign_date.user_id,
        resign_date: moment(String(this.resign_date.date)).format('YYYY-MM-DD'),
        nik: this.resign_date.nik
      }
      console.log(this.resign_date)
      this.$swal({
        title: 'Are you sure?',
        text:
          'Once deleted, you will not be able to recover this employee datas!',
        icon: 'warning',
        buttons: true,
        dangerMode: true
      }).then(data => {
        if (data) {
          console.log(payload)
          BackofficeApi.employeeResign(payload)
            .then(res => {
              console.log(res)
              let payloads = {
                resign_date: moment(String(this.resign_date.date)).format(
                  'YYYY-MM-DD'
                ),
                user_id: this.resign_date.user_id,
                nik: this.resign_date.nik
              }
              EmployeeApi.resign(payloads)
                .then(employeeRes => {
                  console.log(employeeRes)
                  let postPayload = {
                    employee_no: this.resign_date.nik,
                    id: this.resign_date.id
                  }
                  this.updateEmployee(postPayload)
                  this.$swal('Success', res.data.data.message, 'success')
                })
                .catch(employeeRes => {
                  console.log(employeeRes)
                })
              this.loading = true
              this.employeeSearch({
                expand: 'department,occupation,branch,user',
                page: 1,
                limit: this.filter.limit,
                active: 1
              })
              this.$refs['EmployeeResign'].hide()
            })
            .catch(res => {
              console.log(res)
            })
        } else {
          this.$refs['EmployeeResign'].hide()
        }
      })
    },
    resign(employee) {
      console.log(employee)
      this.resign_date.firstname = employee.firstname + ' ' + employee.lastname
      this.resign_date.user_id = employee.user_id
      this.resign_date.nik = employee.employee_no
      this.resign_date.id = employee.id
      this.$refs['EmployeeResign'].show()
    },
    sort(e, order) {
      this.loading = true
      if (this.sort_filter.sort === 'desc') {
        this.sort_filter.sort = 'asc'
      } else {
        this.sort_filter.sort = 'desc'
      }
      this.sort_filter.order = order
      this.applyFilter()
    },
    clickCallback(e) {
      this.loading = true
      const search = {}
      if (
        typeof this.filter.employee_no !== 'undefined' &&
        this.filter.employee_no
      ) {
        search.employee_no = this.filter.employee_no
      }
      if (typeof this.filter.fullname !== 'undefined' && this.filter.fullname) {
        search.fullname = this.filter.fullname
      }
      if (typeof this.filter.email !== 'undefined' && this.filter.email) {
        search.email = this.filter.email
      }
      if (typeof this.filter.cabang !== 'undefined' && this.filter.cabang) {
        search.branch_id = this.filter.cabang
      }
      if (
        typeof this.filter.department !== 'undefined' &&
        this.filter.department
      ) {
        search.department_id = this.filter.department
      }
      if (typeof this.filter.position !== 'undefined' && this.filter.position) {
        search.occupation_id = this.filter.position
      }
      search.expand = 'department,occupation,branch,user'
      search.page = e
      search.limit = this.filter.limit
      search.order = this.sort_filter.order
      search.sort = this.sort_filter.sort
      console.log(search)
      this.employeeSearch(search)
    },
    resetFilter(e) {
      this.pagination.currentPage = 1
      this.loading = true
      this.filter = {
        employee_no: null,
        fullname: null,
        email: null,
        cabang: null,
        department: null,
        position: null,
        limit: 12
      }
      this.employeeSearch({
        expand: 'department,occupation,branch,user',
        page: 1,
        limit: this.filter.limit
      })
      this.listBranch()
      this.listDepartment({ limit: -1 })
      this.listOccupation({ limit: -1 })
    },
    applyFilter(e) {
      this.loading = true
      const search = {}
      search.expand = 'department,occupation,branch,user'
      search.page = 1
      this.pagination.currentPage = 1
      if (
        typeof this.filter.employee_no !== 'undefined' &&
        this.filter.employee_no
      ) {
        search.employee_no = this.filter.employee_no
      }
      if (typeof this.filter.fullname !== 'undefined' && this.filter.fullname) {
        search.fullname = this.filter.fullname
      }
      if (typeof this.filter.email !== 'undefined' && this.filter.email) {
        search.email = this.filter.email
      }
      if (typeof this.filter.cabang !== 'undefined' && this.filter.cabang) {
        search.branch_id = this.filter.cabang
      }
      if (
        typeof this.filter.department !== 'undefined' &&
        this.filter.department
      ) {
        search.department_id = this.filter.department
      }
      if (typeof this.filter.position !== 'undefined' && this.filter.position) {
        search.occupation_id = this.filter.position
      }
      search.order = this.sort_filter.order
      search.sort = this.sort_filter.sort

      console.log(search)
      this.employeeSearch(search)
    },
    listBranch() {
      Master.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know. [CE-BRANCH-001]',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      BranchApi.list()
        .then(result => {
          this.branches = result.data.data.map(x => {
            return {
              value: x.id,
              text: x.name
            }
          })
        })
        .catch(err => {
          console.log(err)
          this.$swal(
            'Ooops!',
            'A network error occurred. ' +
              'It is not possible for us to know. [CE-BRANCH-002]',
            'error'
          )
        })
    },
    salesLiveAccountList(params) {
      BackofficeSalesApi.SalesLiveAccountList(params)
        .then(res => {})
        .catch()
    },
    occupationByDepartment(e) {
      this.filter.position = null
      this.listOccupation({ department_id: e })
    },
    listDepartment(params) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know. [CE-DEPARTMENT-001]',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      DepartmentApi.search(params)
        .then(result => {
          this.departments = result.data.data.map(x => {
            return {
              value: x.id,
              text: x.name
            }
          })
        })
        .catch(err => {
          this.$swal(
            'Ooops!',
            'A network error occurred. ' +
              'It is not possible for us to know. [CE-DEPARTMENT-002]',
            'error'
          )
        })
    },
    listOccupation(params) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know. [CE-OCCUPATION-001]',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      OccupationApi.search(params)
        .then(result => {
          this.occupations = result.data.data.map(x => {
            return {
              value: x.id,
              text: x.name
            }
          })
        })
        .catch(err => {
          this.$swal(
            'Ooops!',
            'A network error occurred. ' +
              'It is not possible for us to know. [CE-OCCUPATION-002]',
            'error'
          )
        })
    },
    employeeSearch(param) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know. [CE-EMPLOYEE-001]',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      EmployeeApi.searchDisable(param)
        .then(res => {
          const that = this
          this.employees = res.data.data
          this.pagination.totalPage = Math.ceil(
            parseInt(res.data.totalData) / parseInt(res.data['per-page'])
          )
          this.pagination.currentPage = parseInt(res.data.page)
          this.pagination.totalData = parseInt(res.data.totalData)
          let perPage = parseInt(res.data['per-page'])
          let display = perPage * this.pagination.currentPage
          if (display >= this.pagination.totalData) {
            this.pagination.displaying = this.pagination.totalData
          } else {
            this.pagination.displaying = display
          }
          this.json_data = res.data.data.map(x => {
            let branch =
              typeof x.branch !== 'undefined' && x.branch ? x.branch.name : null
            let department =
              typeof x.department !== 'undefined' && x.department
                ? x.department.name
                : null
            let position =
              typeof x.occupation !== 'undefined' && x.occupation
                ? x.occupation.name
                : null
            return {
              employee_id: x.employee_no,
              name: x.firstname + ' ' + x.lastname,
              email: x.email,
              cabang: branch,
              department: department,
              position: position
            }
          })

          res.data.data.map(x => {
            if (typeof x.user !== 'undefined' && x.user) {
              if (x.user.active) {
                that.employeeCount.active += 1
              } else {
                that.employeeCount.disable += 1
              }
            }
          })
          this.loading = false
          console.log(this.json_data)
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.$swal(
            'Ooops!',
            'A network error occurred. ' +
              'It is not possible for us to know. [CE-EMPLOYEE-002]',
            'error'
          )
        })
    },
    deleteModal(employee) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know. [CE-EMPLOYEE-001]',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      this.$swal({
        title: 'Are you sure?',
        text:
          'Once deleted, you will not be able to recover this employee datas!',
        icon: 'warning',
        buttons: true,
        dangerMode: true
      }).then(data => {
        if (data) {
          EmployeeApi.delete(employee.id, { employee_id: employee.id })
            .then(result => {
              this.$swal('Employee has been deleted!', {
                icon: 'success'
              }).then(data => {
                this.loading = true
                this.employeeSearch({
                  expand: 'department,occupation,branch,user',
                  page: 1,
                  limit: this.filter.limit
                })
              })
              console.log(result)
            })
            .catch(err => {
              console.log(err)
            })
          console.log(employee)
        } else {
          console.log('Cancled')
        }
      })
    },
    userCount() {
      EmployeeApi.search({}).then(res => {
        console.log('user active count', res)
        this.active_disable.active = this.active_disable.active = res.data.totalData
      })
      EmployeeApi.searchDisable({}).then(res => {
        console.log('user disabled count', res)
        this.active_disable.disable = res.data.totalData
      })
    },
    logthis(e) {
      console.log(e)
      this.modelMutasi.history_date = this.dateToDatabaseFormat(e)
      console.log('e', this.modelMutasi.history_date)
    },
    listWP(query) {
      BackofficeSalesApi.WakilPialangList(query)
        .then(res => {
          console.log(res)
        })
        .catch(error => {
          if (error.response) {
            // Request made and server responded
            console.log('error.response.data', error.response.data)
            console.log('error.response.status', error.response.status)
            console.log('error.response.headers', error.response.headers)
            this.$swal('Ooops!', error.response.data.errors.message, 'error')
          } else if (error.request) {
            // The request was made but no response was received
            console.log('error.request', error.request)
            this.$swal('Ooops!', error.request, 'error')
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
            this.$swal('Ooops!', error.message, 'error')
          }
        })
    },
    mysqlDate(date) {
      date = date || new Date()
      return date.toISOString().split('T')[0]
    },
    submitFormMutasi() {
      let payload = {
        sales_id: this.modelMutasi.user_id,
        wp_id: this.modelMutasi.wp_id,
        history_date: this.modelMutasi.history_date,
        alias_link: this.modelMutasi.alias_link,
        note: this.modelMutasi.note,
        occupation_id: this.modelMutasi.to_occupation_id,
        sales_division: this.modelMutasi.sales_division
      }
      BackofficeSalesApi.SalesDowngradeUpgradeSubmit(payload)
        .then(res => {
          let user_id = JSON.parse(localStorage.getItem('hr_token')).user.id
          let history_payload = {
            employee_id: this.modelMutasi.employee.id,
            user_id: this.modelMutasi.user_id,
            history_date: this.modelMutasi.history_date,
            column_name: 'occupation_id',
            old_value: this.modelMutasi.from_occupation_id,
            new_value: this.modelMutasi.to_occupation_id,
            note: this.modelMutasi.note,
            created_date: this.mysqlDate(),
            created_by_user_id: user_id
          }
          EmployeeApi.historyStore(history_payload)
            .then(res => {
              let user_id = JSON.parse(localStorage.getItem('hr_token')).user.id
              let history_payload = {
                employee_id: this.modelMutasi.employee.id,
                user_id: this.modelMutasi.user_id,
                history_date: this.modelMutasi.history_date,
                column_name: 'upline_id',
                old_value: this.modelMutasi.employee.upline_id,
                new_value: this.modelMutasi.upline_id,
                note: this.modelMutasi.note,
                created_date: this.mysqlDate(),
                created_by_user_id: user_id
              }
              EmployeeApi.historyStore(history_payload)
                .then(res => {
                  EmployeeApi.update(this.modelMutasi.employee.id, {
                    occupation_id: this.modelMutasi.to_occupation_id,
                    upline_id: this.modelMutasi.upline_id
                  })
                    .then(res => {
                      this.$swal(
                        'Hooray!',
                        'Employee Berhasil di Update / Downgrade',
                        'success'
                      )
                      this.$refs['ModalMutasi'].hide()
                    })
                    .catch(error => {
                      if (error.response) {
                        // Request made and server responded
                        console.log('error.response.data', error.response.data)
                        console.log(
                          'error.response.status',
                          error.response.status
                        )
                        console.log(
                          'error.response.headers',
                          error.response.headers
                        )
                        this.$swal(
                          'Ooops!',
                          error.response.data.errors.message,
                          'error'
                        )
                      } else if (error.request) {
                        // The request was made but no response was received
                        console.log('error.request', error.request)
                        this.$swal('Ooops!', error.request, 'error')
                      } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message)
                        this.$swal('Ooops!', error.message, 'error')
                      }
                    })
                })
                .catch(error => {
                  if (error.response) {
                    // Request made and server responded
                    console.log('error.response.data', error.response.data)
                    console.log('error.response.status', error.response.status)
                    console.log(
                      'error.response.headers',
                      error.response.headers
                    )
                    this.$swal(
                      'Ooops!',
                      error.response.data.errors.message,
                      'error'
                    )
                  } else if (error.request) {
                    // The request was made but no response was received
                    console.log('error.request', error.request)
                    this.$swal('Ooops!', error.request, 'error')
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                    this.$swal('Ooops!', error.message, 'error')
                  }
                })
            })
            .catch(error => {
              if (error.response) {
                // Request made and server responded
                console.log('error.response.data', error.response.data)
                console.log('error.response.status', error.response.status)
                console.log('error.response.headers', error.response.headers)
                this.$swal(
                  'Ooops!',
                  error.response.data.errors.message,
                  'error'
                )
              } else if (error.request) {
                // The request was made but no response was received
                console.log('error.request', error.request)
                this.$swal('Ooops!', error.request, 'error')
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message)
                this.$swal('Ooops!', error.message, 'error')
              }
            })
        })
        .catch(error => {
          if (error.response) {
            // Request made and server responded
            console.log('error.response.data', error.response.data)
            console.log('error.response.status', error.response.status)
            console.log('error.response.headers', error.response.headers)
            this.$swal('Ooops!', error.response.data.errors.message, 'error')
          } else if (error.request) {
            // The request was made but no response was received
            console.log('error.request', error.request)
            this.$swal('Ooops!', error.request, 'error')
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
            this.$swal('Ooops!', error.message, 'error')
          }
        })
    },
    modalMutasi(employee) {
      console.log('employee', employee)
      this.modelMutasi.user_id = employee.user_id
      this.modelMutasi.firstname =
        employee.firstname + ' ' + employee.lastname + ' / ' + employee.email
      this.modelMutasi.employee = employee
      this.modelMutasi.upline_id = ''
      this.modelMutasi.sales_code = ''
      this.modelMutasi.wp_id = ''
      this.modelMutasi.history_date = ''
      this.modelMutasi.alias_link = ''
      this.modelMutasi.sales_division = ''
      this.modelMutasi.occupation_id = employee.occupation_id
      this.modelMutasi.from_occupation_id = employee.occupation_id
      this.modelMutasi.to_occupation_id = employee.occupation_id

      this.selectWP()

      OccupationApi.search({ department_id: employee.department_id }).then(
        res => {
          this.modelMutasi.occupations = res.data.data.map(x => {
            return {
              value: x.id,
              text: x.name
            }
          })
        }
      )
      this.$refs['ModalMutasi'].show()
      console.log(this.modelMutasi)
    },
    selectSearch(e) {
      if (e.length >= 3) {
        EmployeeApi.search({
          department_id: this.modelMutasi.employee.department_id,
          branch_id: this.modelMutasi.employee.branch_id,
          fullname: e
        }).then(res => {
          this.modelMutasi.uplines = res.data.data.map(x => {
            return {
              value: x.user_id,
              text: x.firstname + ' / ' + x.email
            }
          })
        })
      }
    },
    selectWP() {
      BackofficeSalesApi.WakilPialangList({ limit: -1 })
        .then(res => {
          this.modelMutasi.wakil_pialang = res.data.data.map(x => {
            return {
              value: x.id,
              text:
                x.first_name + ' ( ' + x.branch_name + ' / ' + x.email + ' )'
            }
          })
        })
        .catch(error => {
          if (error.response) {
            // Request made and server responded
            console.log('error.response.data', error.response.data)
            console.log('error.response.status', error.response.status)
            console.log('error.response.headers', error.response.headers)
            this.$swal('Ooops!', error.response.data.errors.message, 'error')
          } else if (error.request) {
            // The request was made but no response was received
            console.log('error.request', error.request)
            this.$swal('Ooops!', error.request, 'error')
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
            this.$swal('Ooops!', error.message, 'error')
          }
        })
    },
    dateToDatabaseFormat(value) {
      return moment(String(value)).format('YYYY-MM-DD')
    }
  },
  beforeMount() {
    document.title = 'Employee Management'
    this.employeeSearch({
      expand: 'department,occupation,branch,user',
      page: 1,
      limit: this.filter.limit,
      active: 1
    })
    this.listBranch()
    this.listDepartment({ limit: -1 })
    this.listOccupation({ limit: -1 })
    this.userCount()
  }
}
</script>
